<template>
  <div :style="{ height: contentStyleObj }">
    <qzf-tabs v-model:value="value" @tab-click="handleClick">
      <qzf-tabs-item label="申报" name="1" :cache="false">
        <batchSendGsSb ref="batchSendGsSb" :activeName = "activeName"></batchSendGsSb>
      </qzf-tabs-item>
      <qzf-tabs-item label="扣款" name="2" :cache="false">
        <batchSendGsKk></batchSendGsKk>
      </qzf-tabs-item>
      <qzf-tabs-item label="作废" name="3" :cache="false">
        <batchSendGsZf></batchSendGsZf>
      </qzf-tabs-item>
      <qzf-tabs-item label="更正" name="4" :cache="false">
        <batchSendGsGz></batchSendGsGz>
      </qzf-tabs-item>
      <qzf-tabs-item label="完税证明" name="5" :cache="false">
        <batchSendGsProve></batchSendGsProve>
      </qzf-tabs-item>
    </qzf-tabs>
 </div>
</template>

<script>
import batchSendGsSb from "./components/batchSendGsSb"
import batchSendGsKk from "./components/batchSendGsKk"
import batchSendGsZf from "./components/batchSendGsZf"
import batchSendGsGz from "./components/batchSendGsGz.vue"
import batchSendGsProve from "./components/batchSendGsProve.vue"
export default {
  name:'gsgsSb',
  components:{
    batchSendGsSb,
    batchSendGsKk,
    batchSendGsZf,
    batchSendGsGz,
    batchSendGsProve
  },
  data(){
    return {
      activeName:'first',
      value:'1',
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj:[1],
        from:'init'
      },
      contentStyleObj: {}, //高度变化
    }
  },
  created(){
    this.contentStyleObj = this.$getHeight(190);
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>

</style>