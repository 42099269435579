<template>
  <div style="position: relative;">
   <div class="export_btn">
    <el-button size="small" type="success" @click="daochu()" :disabled="!$buttonStatus('gssb_dc')">
      <el-icon><Folder /></el-icon><span  > 导出</span>
    </el-button>
   </div>
   <!-- todo -->
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="税款申报" name="税款申报"></el-tab-pane>
      <el-tab-pane label="零申报" name="零申报"></el-tab-pane>
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 19px;width:685px" label-width="100px" :inline="true">
            <el-form-item label="税局 ："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:170px"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员 ：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:170px"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型 ：">          
              <selecttaxtype v-model:type="listQuery.type" style="width:170px"></selecttaxtype>
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:170px">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申报状态：">
              <el-checkbox-group size="small" v-model="listQuery.allStatus2">
                <div>  
                  <el-checkbox-button v-for="(item, index) in sbOptions.slice(0, 5)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in sbOptions.slice(5, 10)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in sbOptions.slice(10, 15)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="更正状态：">
              <el-checkbox-group size="small" v-model="listQuery.allStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
        <span class="hint">
        <i class="iconfont icon-sanjiaotanhao"></i
            >温馨提示：请优先确认系统内工资表数据准确无误，再进行更正
        </span>
      </div>
      <div class="right_box">
        <el-button :disabled="!$buttonStatus('gssb_gz')" size="small" type="primary" @click="declaration()" :loading="gzLoading">
          <el-icon><Money /></el-icon><span  > 批量更正</span>
        </el-button>
      </div>
    </div>
    <div class=" loudou">
      <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange" @filter-change="tableFilterChange" class="date-icon" @sort-change="sortChange" v-loading="listLoading" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column align="left" prop="name" fixed label="公司名称" min-width="300">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false">
            </TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column  prop="district" label="税局" width="65">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district)  }}</span>  
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报税种" width="280"
        :filters="this.categories">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p style="text-align: left;">{{$tableNameFilter(item.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="应补（退）税额" min-width="130">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.tax">{{item.tax}}</p>
              <p v-else>{{0}}</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="left" prop="districtName" label="申报结果" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div>
                <div class="item_icon">
                  <p>
                    <i :class="$setUpStatusGsIconSb(item.taskStatusSb,item.bussinessStatusSb)"></i>
                    <el-tooltip v-if="item.bussinessLogSb" class="item" effect="dark" placement="top-start">
                      <template #content>
                        <div v-html="item.businessLogSb?item.businessLogSb:'' + item.errLogSb?item.errLogSb:''"></div>
                      </template>
                      <span :class="$setUpStatusGsWordSb(item.taskStatusSb, item.bussinessStatusSb)">{{$setUpStatusGsSb(item.taskStatusSb, item.bussinessStatusSb)}}</span>
                     
                    </el-tooltip>
                    <span v-else>
                      <span :class="$setUpStatusGsWordSb(item.taskStatusSb, item.bussinessStatusSb)">{{$setUpStatusGsSb(item.taskStatusSb, item.bussinessStatusSb)}}</span>
                    </span>
                  </p>
                  <el-tooltip :disabled="(item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:''"></div>
                    </template>
                    <div style="display:inline-block">
                      <i class="iconfont icon-wenhao" v-if="(item.errLog && item.errLog != '成功')|| (item.bussinessLog && item.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="left" prop="districtName" label="更正结果" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index" style="height: 24px;line-height: 24px;">
              <div v-if="item.tableName == 'gs_gs'">
                <span v-if="item.image" class="demo-image__preview" >
                  <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;cursor: pointer;" @click="open(item, index,scope.$index)"></i>
                </span>
                <div class="item_icon">
                  <p>
                    <i v-if="!item.image" :class="$setUpStatusGsIconSb(item.taskStatus,item.bussinessStatus)"></i>
                    <el-tooltip v-if="item.errLog" class="item" effect="dark" placement="top-start">
                      <template #content>
                        <div v-html="item.businessLog?item.businessLog:'' + item.errLog?item.errLog:''"></div>
                      </template>
                      <span :class="$setUpStatusGsWordSb(item.taskStatus,item.bussinessStatus)" @click="item.image?open(item,index, scope.$index):''">{{$setUpStatusGsGz(item.taskStatus, item.bussinessStatus)}}</span>
                    </el-tooltip>
                    <span v-else>
                      <span :class="$setUpStatusGsWordSb(item.taskStatus,item.bussinessStatus)" @click="item.image?open(item, index,scope.$index):''">{{$setUpStatusGsGz(item.taskStatus, item.bussinessStatus)}}</span>
                    </span>
                  </p>
                  <el-tooltip :disabled="(item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:''"></div>
                    </template>
                    <div style="display:inline-block">
                      <i class="iconfont icon-wenhao" v-if="(item.errLog && item.errLog != '成功')|| (item.bussinessLog && item.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index" style="height: 24px;line-height: 24px;">
              <div v-if="item.tableName == 'gs_gs'">
                <el-button :disabled="!$buttonStatus('gssb_gz') || [1,2,99].includes(item.taskStatus) || item.taskStatusSb != 3 || (item.taskStatusSb == 3 && (item.bussinessStatusSb != 10 && item.bussinessStatusSb != 7 && item.bussinessStatusSb != 13 && item.bussinessStatusSb != 20))" @click="handleUpdate(item,scope.row)" size="small" type="text">
                  更正</el-button>
                <el-button :disabled="item.taskStatus == 0" @click="declareDetail(scope.row,item)" size="small" type="text">
                  更正记录</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="batchSendGsZf"/>
    </div>
    </el-tabs>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <!-- 图片 -->
  <sbImage ref="sbImage"></sbImage>
</template>

<script>
import { companySendTaxList } from "@/api/company"
import { declarationModuleExport } from '@/api/export'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import selectcity from "@/components/Screening/selectcity";
import sbImage from '@/components/sbImage'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "batchSendGsGz",
  components:{
    selecttaxtype,
    declareDetails,
    sbImage,
    selectcity,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      activeName:'税款申报',
      listQuery: {
        page: 1,
        limit: 20,
        taskName:'gs-gz-',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        accountingType: 2,
        taxNames:[],
        from: '个税',
        taxStatus: 0
      },
      total:0,
      list:[],
      gsMainIds: [],
      comIds: [],
      pageStatus: true,
      sbOptions:[
        {
          value: "task-0",
          label: '未申报',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '申报中',
        },
        {
          value: "business-11",
          label: '申报扣款中',
        },
        {
          value: "business-20",
          label: '申报成功',
        },
        {
          value: "business-7",
          label: '申报成功，待缴款',
        },
        {
          value: "business-13",
          label: '申报成功，扣款成功',
        },
        {
          value: "business-10",
          label: '申报成功，无需缴款',
        },
        {
          value: "business-14",
          label: '已申报过',
        },
        {
          value: "business-3",
          label: '超时失败',
        },
        {
          value: "business-5",
          label: '申报异常',
        },
        {
          value: "business-6",
          label: '申报失败',
        },
        {
          value: "business-12",
          label: '扣款异常',
        },{
          value: "business-2",
          label: '异常',
        },
      ],
      options: [
        {
          value: "task-0",
          label: '未更正',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '更正中',
        },
        {
          value: "business-3",
          label: '超时失败',
        },
        {
          value: "business-6",
          label: '申报失败',
        },
        {
          value: "business-7",
          label: '申报成功，待缴款',
        },
        {
          value: "business-10",
          label: '申报成功，无需缴款',
        },{
          value: "business-11",
          label: '申报扣款中',
        },{
          value: "business-12",
          label: '扣款异常',
        },{
          value: "business-13",
          label: '申报成功，扣款成功',
        },{
          value: "business-14",
          label: '已申报过',
        },{
          value: "business-20",
          label: '申报成功',
        },
        {
          value: "business-2",
          label: '异常',
        },
      ],
      categories: [
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
        {text: '个人生产经营所得(核定征收)', value: 'gs_gs_hd'},
      ],
      listLoading:false,
      sels:[],
      currentYear:"",
      gzLoading:false,
      selects:[],
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.currentYear = new Date().getFullYear();
    this.listQuery.limit = localStorage.getItem('batchSendGsZf') ? localStorage.getItem('batchSendGsZf')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(251)
    this.initBus()
  },
  methods:{
    // 重置
    cancel(){
      let originLimit =this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        taskName:'gs-sb-',
        name: '',
        period: currentAccountPeriod(),
        businessStatus: [],
        taskStatus: [],
        allStatus: [],
        accountingType: null,
        taxNames:[],
        from: '个税',
        taxStatus: null,
        districtCode:null,
        userId:null,
        type:null,
        jzStatus:null,
        gsMainStatus:null,
      }
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
      }
      this.getList()
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          res.items.forEach(item => {
            if ([1,2,99].includes(item.taskStatus)) {
              status = true;
            }
          })
        })
        if(status == true) {
          companySendTaxList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    getList(){
      this.listLoading = true
      companySendTaxList(this.listQuery).then(res=>{
        this.listLoading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      this.comIds = []
      this.sels = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    open(row, index, bigIndex) {
      let imageAll = []
      let currentIndex = index
      this.list.map((v,i)=>{
        let arr = []
        v.items.map((item,i2)=>{
          let imgList = []
          if(item.image.match(',')){
            let imgUrls = item.image.split(',')
            imgList = imgUrls.filter(v=>{return v})
            imgList.map((e,i3)=>{
              if(e){
                arr.push({
                  img:'https://file.listensoft.net' + e + "?" + Math.random(10),
                  tableName:item.tableName
                })
                if(i2 < index && i == bigIndex && i3>0){
                  currentIndex ++
                }
              }
            })
          }else{
            if(item.image){
              arr.push({
                img:'https://file.listensoft.net' + item.image + "?" + Math.random(10),
                tableName:item.tableName
              })
            }else{
              if(i2<index && i == bigIndex){
                currentIndex --
              }
            }
          }
        })
        let url = {
          name:v.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll,bigIndex,currentIndex)
    },
    handleClick(tab) {
      if(tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    getIds(){
      this.gsMainIds = []
      this.comIds = []
      this.sels = []
      this.selects.map(info=>{
        this.comIds.push(info.comId)
        info.items.map(item=>{
          this.sels.push(item)
          this.gsMainIds.push(item.gsMainId)
        })
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.comIds = []
        this.sels = []
        this.allChecked = false
      }
    },
    // 申报详情
    declareDetail(e,v) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,v.taskName)
    },
    declaration() {
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      if(this.listQuery.period.slice(0,4) != this.currentYear){
        this.$qzfMessage('更正往年数据请通过个税同步助手操作', 1)
        return
      }
      let status = false
      this.sels.map(v=>{
        if(v.tableName == 'gs_gs' && v.taskStatusSb != 3 || (v.taskStatusSb == 3 && v.bussinessStatusSb != 10 && v.bussinessStatusSb != 7  && v.bussinessStatusSb != 13 && v.bussinessStatusSb != 20)){
          status = true
          return
        }
      })
      if(status){
        this.$qzfMessage("不可发起更正", 1)
        return
      }
      let timeStr = ''
      this.selects.map(v=>{
        let date1 = new Date(v.sbTime)
        let date2 = new Date(v.sheetTime)
        if(date1 > date2 && v.gzLastStatus != '3'){
          timeStr = v.name + '申报后未更新过工资表，请重新选择'
          return
        }
      })
      if(timeStr){
        this.$qzfMessage(timeStr, 1)
        return
      }
      let proceed = ''
      this.selects.map(item=>{
        item.items.map(v=>{
          if([1,2,99].includes(v.taskStatus)){
            proceed = item.name + '任务正在进行中，请重新选择！';
            return
          }
        })
      })
      if(proceed){
        this.$qzfMessage(proceed, 1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        if(v.tableName == 'gs_gs'){
          ids.push(v.gsMainId)
        }
      })
      let param = {
        taskName: 'tax_gz',
        gsMainIds: ids
      }
      this.$confirm('确定要更正吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.gzLoading = true
        sendTask(param).then(res => {
          this.gzLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
    //更正
    handleUpdate(e,row) {
      if(this.listQuery.period.slice(0,4) != this.currentYear){
        this.$qzfMessage('更正往年数据请通过个税同步助手操作', 1)
        return
      }
      let date1 = new Date(row.sbTime)
      let date2 = new Date(row.sheetTime)
      if(date1 > date2 && row.gzLastStatus != '3'){
        this.$qzfMessage('申报后未更新过工资表', 1)
        return
      }
      this.$confirm('确定要更正吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax_gz',
          gsMainIds: [e.gsMainId]
        }
        this.listLoading = true
        sendTask(param).then(res => {
          this.listLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
     //导出
    daochu(){
      let param = {
        query:this.listQuery,
        comIds: this.comIds
      }
      declarationModuleExport(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  border-bottom: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
// .xgmStyle,.ybStyle {
//   margin-left: 10px;
// }
.icon-picture{
  margin-right: 4px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.export_btn{
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
.hint{
  font-size: 13px;
  color: red;
  margin-left: 5px;
  .icon-sanjiaotanhao{
    color:#e6a23c;
    margin-right:4px
  }
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}
.loudou .el-icon-arrow-down:before{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>