<template>
  <div style="position: relative;">
   <div class="export_btn">
    <el-button size="small" type="success" @click="daochu()" :disabled="!$buttonStatus('gssb_dc')">
      <el-icon><Folder /></el-icon><span  > 导出</span>
    </el-button>
   </div>
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="税款申报" name="税款申报"></el-tab-pane>
      <el-tab-pane label="零申报" name="零申报"></el-tab-pane>
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 6px;width:685px" class="styleForm" :inline="true">
            <el-form-item label="税局 ：" :label-width="formLabelWidth"> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:130px"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:130px"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
              <selecttaxtype v-model:type="listQuery.type" style="width:130px"></selecttaxtype>
            </el-form-item>
            <el-form-item label="公司类型：" :label-width="formLabelWidth"> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:130px">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申报状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.allStatus2">
                <div>  
                  <el-checkbox-button v-for="(item, index) in optionsSb.slice(0, 5)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in optionsSb.slice(5, 10)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in optionsSb.slice(10, 15)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="扣款状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.allStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
        <el-radio-group v-model="listQuery.taxStatus" style="margin-left:5px" size="small" @change="getList">
          <el-radio-button :label="0" >全部</el-radio-button>
          <el-radio-button :label="1" >金额不为0</el-radio-button>
          <el-radio-button :label="2" >金额为0</el-radio-button>
        </el-radio-group>
      </div>
      <div class="right_box">
        <el-button :disabled="!$buttonStatus('gssb_kk_kk')" size="small" type="primary" @click="declaration()" :loading="kkLoading">
          <el-icon><Money /></el-icon><span  > 批量扣款</span>
        </el-button>
      </div>
    </div>
    <div class=" loudou">
      <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange" @filter-change="tableFilterChange" class="date-icon" v-loading="listLoading" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column align="left" prop="name" fixed label="公司名称" min-width="300">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column  prop="district" label="税局" width="65">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district)  }}</span>  
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报税种" width="280"
        :filters="this.categories">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p style="text-align: left;">{{$tableNameFilter(item.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="应补（退）税额" min-width="140">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.tax">{{item.tax}}</p>
              <p v-else>{{0}}</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="left" prop="districtName" label="申报结果" min-width="180">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div>
                <span v-if="item.imageSb" class="demo-image__preview" >
                  <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;cursor: pointer;" @click="openSb(item, index,scope.$index)"></i>
                </span>
                <div class="item_icon">
                  <p>
                    <i v-if="!item.imageSb" :class="$setUpStatusGsIconSb(item.taskStatusSb,item.bussinessStatusSb)"></i>
                    <el-tooltip v-if="item.bussinessLogSb" class="item" effect="dark" placement="top-start">
                      <template #content>
                        <div v-html="item.businessLogSb?item.businessLogSb:'' + item.errLogSb?item.errLogSb:''"></div>
                      </template>
                      <span :class="$setUpStatusGsWordSb(item.taskStatusSb,item.bussinessStatusSb)" @click="item.imageSb?openSb(item, index,scope.$index):''">{{$setUpStatusGsSb(item.taskStatusSb, item.bussinessStatusSb)}}</span>
                    </el-tooltip>
                    <span v-else>
                      <span :class="$setUpStatusGsWordSb(item.taskStatusSb,item.bussinessStatusSb)" @click="item.imageSb?openSb(item, index,scope.$index):''">{{$setUpStatusGsSb(item.taskStatusSb, item.bussinessStatusSb)}}</span>
                    </span>
                  </p>
                  <el-tooltip :disabled="(item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:''"></div>
                    </template>
                    <div style="display:inline-block">
                      <i class="iconfont icon-wenhao" v-if="(item.errLog && item.errLog != '成功')|| (item.bussinessLog && item.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="left" prop="districtName" label="扣款结果" min-width="240">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div>
                <span v-if="item.image" class="demo-image__preview" >
                  <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;cursor: pointer;" @click="open(item,index, scope.$index)"></i>
                </span>
                <div class="item_icon">
                  <p>
                    <i v-if="!item.image"  :class="$setUpStatusGsIconSb(item.taskStatus,item.bussinessStatus)"></i>
                    <el-tooltip v-if="item.bussinessLog" class="item" effect="dark" placement="top-start">
                      <template #content>
                        <div v-html="item.businessLog?item.businessLog:'' + item.errLog?item.errLog:''"></div>
                      </template>
                      <span :class="$setUpStatusGsWordSb(item.taskStatus, item.bussinessStatus)" @click="item.image?open(item,index, scope.$index):''">{{$setUpStatusGsKk(item.taskStatus, item.bussinessStatus)}}</span>
                    </el-tooltip>
                    <span v-else>
                      <span :class="$setUpStatusGsWordSb(item.taskStatus, item.bussinessStatus)" @click="item.image?open(item,index, scope.$index):''">{{$setUpStatusGsKk(item.taskStatus, item.bussinessStatus)}}</span>
                    </span>
                  </p>
                  <el-tooltip :disabled="(item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:''"></div>
                    </template>
                    <div style="display:inline-block">
                      <i class="iconfont icon-wenhao" v-if="(item.errLog && item.errLog != '成功')|| (item.bussinessLog && item.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
              
              <div class="timeJe">
                <div>{{item.endAt}}</div>
                <div style="width: 90px;text-align: left;"><span style="color: #67c23a;">金额：</span>
                  <span v-if="item.amountPaid">{{item.amountPaid}}</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" width="160">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <el-button :disabled="!$buttonStatus('gssb_kk_kk') || item.tableName != 'gs_gs' || [1,2,99].includes(item.taskStatus)" @click="handleUpdate(item)" size="small" type="text" >扣款</el-button>
              <el-button :disabled="item.taskStatus == 0" @click="declareDetail(scope.row,item)" size="small" type="text">扣款记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="batchSendGsKk"/>
    </div>
    </el-tabs>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <!-- 图片 -->
  <sbImage ref="sbImage"></sbImage>
</template>

<script>
import { companySendTaxList } from "@/api/company"
import { declarationModuleExport } from '@/api/export'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import selectcity from "@/components/Screening/selectcity";
import sbImage from '@/components/sbImage'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "batchSendGsKk",
  components:{
    selecttaxtype,
    declareDetails,
    sbImage,
    selectcity,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      activeName:'税款申报',
      formLabelWidth:'100px',
      listQuery: {
        page: 1,
        limit: 20,
        taskName:'gs-kk-',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        allStatus2: [],
        accountingType: 2,
        taxNames:[],
        from: '个税',
        taxStatus: 0,
        companyType:''
      },
      total:0,
      list:[],
      gsMainIds: [],
      comIds: [],
      pageStatus: true,
      options: [
        {
          value: "task-0",
          label: '未扣款',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '扣款中',
        },
        {
          value: "business-13",
          label: '扣款成功',
        },
        {
          value: "business-10",
          label: '无需扣款',
        },
        {
          value: "business-12",
          label: '扣款异常',
        }
      ],
      optionsSb: [
        {
          value: "task-0",
          label: '未申报',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '申报中',
        },
        {
          value: "business-11",
          label: '申报扣款中',
        },
        {
          value: "business-20",
          label: '申报成功',
        },
        {
          value: "business-7",
          label: '申报成功，待缴款',
        },
        {
          value: "business-13",
          label: '申报成功，扣款成功',
        },
        {
          value: "business-10",
          label: '申报成功，无需缴款',
        },
        {
          value: "business-14",
          label: '已申报过',
        },
        {
          value: "business-3",
          label: '超时失败',
        },
        {
          value: "business-5",
          label: '申报异常',
        },
        {
          value: "business-6",
          label: '申报失败',
        },
        {
          value: "business-12",
          label: '扣款异常',
        },{
          value: "business-2",
          label: '异常',
        },
      ],
      categories: [
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
      ],
      kkStatus: undefined,
      listLoading:false,
      kkLoading:false,
      kkOneStatus:false,
      selects:[],
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.listQuery.limit = localStorage.getItem('batchSendGsKk') ? localStorage.getItem('batchSendGsKk')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(251)
    this.initBus()
  },
  methods:{
    // 重置
    cancel(){
      let originLimit =this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        taskName:'gs-sb-',
        name: '',
        period: currentAccountPeriod(),
        businessStatus: [],
        taskStatus: [],
        allStatus: [],
        allStatus2: [],
        accountingType: null,
        taxNames:[],
        from: '个税',
        taxStatus: null,
        districtCode:null,
        userId:null,
        type:null,
        jzStatus:null,
        gsMainStatus:null,
        companyType:''
      }
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
      }
      this.getList()
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          res.items.forEach(item => {
            if ([1,2,99].includes(item.taskStatus)) {
              status = true;
            }
          })
        })
        if(status == true) {
          companySendTaxList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    getList(){
      this.listLoading = true
      companySendTaxList(this.listQuery).then(res=>{
        this.listLoading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      this.comIds = []
      this.kkStatus = false
      this.kkOneStatus = false
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
  
    handleClick(tab) {
      if(tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    getIds(){
      this.gsMainIds = []
      this.comIds = []
      this.selects.map(info=>{
        this.comIds.push(info.comId)
        info.items.map(item=>{
          this.gsMainIds.push(item.gsMainId)
        })
      })
      this.kkStatus = false
      this.kkOneStatus = false
      this.selects.map(item => {
        item.items.map(res => {
          if(res.bussinessStatusSb != 7 && res.bussinessStatusSb != 20) {
            this.kkStatus = true
          }
        })
      })
      this.selects.map(item => {
        item.items.map(res => {
          if(res.tableName != "gs_gs") {
            this.kkOneStatus = true
          }
        })
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.comIds = []
        this.kkStatus = false
        this.kkOneStatus = false
        this.allChecked = false
      }
    },
    // 申报详情
    declareDetail(e,v) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,v.taskName)
    },
    //批量扣款
    declaration() {
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      if(this.kkStatus) {
        this.$qzfMessage('请先进行申报',1)
      } else if(this.kkOneStatus) {
        this.$qzfMessage('暂不支持',1)
      }else{
        let proceed = ''
        this.selects.map(item=>{
          item.items.map(v=>{
            if([1,2,99].includes(v.taskStatus)){
              proceed = item.name + '任务正在进行中，请重新选择！';
              return
            }
          })
        })
        if(proceed){
          this.$qzfMessage(proceed, 1)
          return
        }
        this.kkLoading = true
        let param = {
          taskName: 'tax_kk',
          gsMainIds: this.gsMainIds
        }
        sendTask(param).then(res => {
          this.kkLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      }
    },
    //扣款
    handleUpdate(e) {
      if(e.tableName == 'gs_gs'){
        if(e.bussinessStatusSb == 7) {
          this.kkSure(e)
        }else{
          this.$qzfMessage('请先进行申报!',1)
        }
      }else{
        if(e.bussinessStatusSb == 20 && e.tax){
          this.kkSure(e)
        }else{
          this.$qzfMessage('请先进行申报!',1)
        }
      }
    },
    kkSure(e){
      this.$confirm('确定要缴费吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true
        let param = {
          taskName: 'tax_kk',
          gsMainIds: [e.gsMainId]
        }
        sendTask(param).then(res => {
          this.listLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
    //导出
    daochu(){
      let param = {
        query:this.listQuery,
        comIds: this.comIds
      }
      declarationModuleExport(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    openSb(row, index, bigIndex) {
      let imageAll = []
      let currentIndex = index
      this.list.map((v,i)=>{
        let arr = []
        v.items.map((item,i2)=>{
          let imgList = []
          if(item.imageSb.match(',')){
            let imgUrls = item.imageSb.split(',')
            imgList = imgUrls.filter(v=>{return v})
            imgList.map((e,i3)=>{
              if(e){
                arr.push({
                  img:'https://file.listensoft.net' + e + "?" + Math.random(10),
                  tableName:item.tableName
                })
                if(i2 < index && i == bigIndex && i3>0){
                  currentIndex ++
                }
              }
            })
          }else{
            if(item.imageSb){
              arr.push({
                img:'https://file.listensoft.net' + item.imageSb + "?" + Math.random(10),
                tableName:item.tableName
              })
            }else{
              if(i2<index && i == bigIndex){
                currentIndex --
              }
            }
          }
        })
        let url = {
          name:v.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll,bigIndex,currentIndex)
    },
    open(row, index,bigIndex) {
      let imageAll = []
      let currentIndex = index
      this.list.map((v,i)=>{
        let arr = []
        v.items.map((item,i2)=>{
          let imgList = []
          if(item.image.match(',')){
            let imgUrls = item.image.split(',')
            imgList = imgUrls.filter(v=>{return v})
            imgList.map((e,i3)=>{
              if(e){
                arr.push({
                  img:'https://file.listensoft.net' + e + "?" + Math.random(10),
                  tableName:item.tableName
                })
                if(i2 < index && i == bigIndex && i3>0){
                  currentIndex ++
                }
              }
            })
          }else{
            if(item.image){
              arr.push({
                img:'https://file.listensoft.net' + item.image + "?" + Math.random(10),
                tableName:item.tableName
              })
            }else{
              if(i2<index && i == bigIndex){
                currentIndex --
              }
            }
          }
        })
        let url = {
          name:v.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll,bigIndex,currentIndex)
    },
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
    display: flex;
    align-items: center;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  border-bottom: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    color: #333;
  }
}
.export_btn{
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}
.timeJe {
  display: flex;
  justify-content: space-around;
}
.loudou .el-icon-arrow-down:before{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>