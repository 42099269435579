<template>
  <div style="position: relative;">
   <div class="export_btn">
    <el-button size="small" type="success" @click="daochu()" :disabled="!$buttonStatus('gssb_dc')">
      <el-icon><Folder /></el-icon><span  > 导出</span>
    </el-button>
   </div>
   <!-- todo -->
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="税款申报" name="税款申报"></el-tab-pane>
      <el-tab-pane label="零申报" name="零申报"></el-tab-pane>
      <el-tab-pane label="异常公司" name="异常公司"></el-tab-pane>
      <el-tab-pane label="本期无需申报" name="本期无需申报"></el-tab-pane>
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 10px;width:685px" label-width="100px" :inline="true">
            <el-form-item label="税局 ："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:160px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员 ：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:160px;margin: 0;"></selectuser>
            </el-form-item>
            
            <el-form-item label="纳税人类型 ：">          
              <selecttaxtype v-model:type="listQuery.type" style="width:160px;margin: 0;"></selecttaxtype>
            </el-form-item>
            <el-form-item label="随手记 ：" v-show="activeName != '异常公司' && activeName != '本期无需申报'">
              <el-input v-model="listQuery.jotDown" style="width: 160px;margin: 0;" size="small" placeholder="请输入随手记" />
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:160px;margin: 0;">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结账状态 ：" v-show="activeName != '异常公司' && activeName != '本期无需申报'" >          
              <el-radio-group v-model="listQuery.jzStatus" style="width:295px">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结账</el-radio>
                <el-radio :label="2">未结账</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="取数状态 ：" v-show="activeName != '异常公司' && activeName != '本期无需申报'">          
              <el-radio-group v-model="listQuery.gsMainStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已取数</el-radio>
                <el-radio :label="2">未取数</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="申报状态：" v-show="activeName != '异常公司' && activeName != '本期无需申报'">
              <el-checkbox-group size="small" v-model="listQuery.allStatus">
                <!-- <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button> -->
                <div>  
                  <el-checkbox-button v-for="(item, index) in options.slice(0, 5)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in options.slice(5, 10)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in options.slice(10, 15)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="税款:" v-show="activeName != '异常公司' && activeName != '本期无需申报'">
              <el-radio v-model="listQuery.taxStatus" :label="0">全部</el-radio>
              <el-radio v-model="listQuery.taxStatus" :label="1">金额不为0</el-radio>
              <el-radio v-model="listQuery.taxStatus" :label="2">金额为0</el-radio>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button :disabled="!$buttonStatus('gssb_sb_plfz')" size="small" type="success" @click="batchCopySalary()"  :loading="loading" v-show="activeName != '异常公司' && activeName != '本期无需申报'">
          <el-icon><List /></el-icon><span  > 复制往期薪酬</span>
        </el-button>
        <el-button :disabled="!$buttonStatus('gssb_sb_sb')" size="small" type="primary" @click="declaration()" :loading="sbLoading" v-show="activeName != '异常公司' && activeName != '本期无需申报'">
          <el-icon><List /></el-icon><span  > 申报个税</span>
        </el-button>
      </div>
    </div>
    <div class=" loudou">
      <el-table stripe :data="list" :height="contentStyleObj"  border @select="handleSelectionChange" @filter-change="tableFilterChange" class="date-icon" v-loading="listLoading" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column align="left" prop="name" fixed label="公司名称" min-width="260">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false">
            </TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column  prop="district" label="税局" width="65">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district)  }}</span>  
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报税种" width="230"
        :filters="this.categories" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p style="text-align: left;">{{$tableNameFilter(item.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="系统应补（退）税额" width="155" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.tax">{{item.tax}}</p>
              <p v-else>{{0}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="取数" width="100" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.gsMainStatus == '1'" style="display:inline-block">已取数</p>
              <p v-if="item.gsMainStatus == '2'" style="display:inline-block">未取数</p>
              <el-button size="small" type="text" :disabled="item.gsMainStatus!='1'" @click="checkTableDialog(scope.row,item)" v-if="item.tableName=='gs_gs'">查看</el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="left" prop="districtName" label="申报结果" min-width="180" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div>
                <div class="item_icon">
                  <p>
                    <i  :class="$setUpStatusGsIconSb(item.taskStatus,item.bussinessStatus)"></i>
                    <el-tooltip v-if="scope.row.bussinessLog" class="item" effect="dark" placement="top-start">
                      <template #content>
                        <div v-html="item.businessLog?item.businessLog:'' + item.errLog?item.errLog:''"></div>
                      </template>
                      <span :class="$setUpStatusGsWordSb(item.taskStatus,item.bussinessStatus)" @click="item.image?open(item, index,scope.$index):''">{{$setUpStatusGsSb(item.taskStatus,item.bussinessStatus)}}</span>
                    </el-tooltip>
                    <span v-else :class="$setUpStatusGsWordSb(item.taskStatus,item.bussinessStatus)" @click="item.image?open(item, index,scope.$index):''">
                      {{$setUpStatusGsSb(item.taskStatus,item.bussinessStatus)}}
                    </span>
                  </p>
                  <span v-if="item.image" class="demo-image__preview" style="margin-left: 4px;">
                    <i class="iconfont icon-picture" style="color:red;font-size:13px;margin-right: 4px;" v-if="item.bussinessStatus == 3 ||item.bussinessStatus == 4 ||item.bussinessStatus == 5 || item.bussinessStatus == 6|| item.bussinessStatus == 12" @click="open(item, index,scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#e6a23c;font-size:13px;margin-right: 4px" v-if=" item.bussinessStatus == 8 " @click="open(item, index,scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;margin-right: 4px" v-if="item.bussinessStatus == 10 ||item.bussinessStatus == 14 ||item.bussinessStatus == 13 || item.bussinessStatus == 20 " @click="open(item, index,scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;margin-right: 4px" v-if="(item.bussinessStatus == 11 || item.bussinessStatus == 2 || item.bussinessStatus == 7)" @click="open(item, index,scope.$index)"></i>
                  </span>
                  <el-tooltip :disabled="(item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:''"></div>
                    </template>
                    <div style="display:inline-block">
                      <i class="iconfont icon-wenhao" v-if="(item.errLog && item.errLog != '成功')|| (item.bussinessLog && item.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" label="扣缴端税额" min-width="120" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.taskStatus == 3">
                <span v-if="((this.eval1(item.tax) != item.amountPaid*1) && this.eval1(item.tax) >= 1) || ((this.eval1(item.tax) != item.amountPaid*1) && this.eval1(item.tax) == 0)" style="color:red">{{item.amountPaid}}(与系统税款不一致)</span>
                <span v-else-if="(this.eval1(item.tax) != item.amountPaid*1) && this.eval1(item.tax) < 1" style="color:#ffa500">{{item.amountPaid}}(已减免)</span>
                <span v-else>{{item.amountPaid}}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="content" label="随手记" min-width="120" v-if="this.listQuery.accountingType != 3 && $buttonStatus('jzkh_ssj')" show-overflow-tooltip>
          <template #default="scope">
            <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
            <span>{{scope.row.jotDown}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="this.listQuery.accountingType == 3" label="最后结账账期" align="center" min-width="220">
          <template #default="scope">
            {{scope.row.curPeriod}}
          </template>
        </el-table-column>
        <el-table-column v-if="this.listQuery.accountingType == 3 && activeName == '本期无需申报'" label="操作" align="center" min-width="180">
          <template #default="scope">
            <el-button @click="checkCate(scope.row)" size="small" type="primary" plain>查看税种</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" width="160" v-if="this.listQuery.accountingType != 3">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <el-button :disabled="!$buttonStatus('gssb_sb_sb') || (item.taskStatus == 3 && [7,10,11,13,20].includes(item.bussinessStatus)) || item.gsMainStatus!=1 || [1,2,99].includes(item.taskStatus)" @click="handleUpdate(item)" size="small" type="text">申报</el-button>
              <el-button :disabled="item.taskStatus == 0" @click="declareDetail(scope.row,item)" size="small" type="text">申报记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="batchSendGsSb"/>
    </div>
    </el-tabs>
    <!-- 复制往期 -->
    <el-dialog :close-on-click-modal="false"
      v-model="dialogFormVisible"
      width="21%"
      destroy-on-close
      align-center
    >
      <template #header>
        <div style="font-size: 17px;">
          请输入复制比例（%）
        </div>
      </template>
      <el-form :model="tempbqf" style="margin: -12px 0px -20px 0px">
        <div style="margin-bottom: 16px;color: red;font-size: 18px;">注：工资薪酬会按照本比例复制</div>
        <el-form-item label="">
          <el-input class="input" size="small" v-model="tempbqf.bfq" style="height: 32px;width: 89%;" :disabled="bfqShow"></el-input>
          <el-icon style="font-size: 23px;margin-left: 5px;" v-if="bfqShow" @click="editBfq"><Edit /></el-icon>
          <div class="icon iconfont icon-baocun" v-else style="margin-left: 5px;font-size: 23px;" @click="editSaveBfq"></div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="saveBfq()">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <declareDetails ref="declareDetails" :changeTaxKind="false"></declareDetails>
  <checkTbaleDialogs ref="checkTbaleDialogs"></checkTbaleDialogs>
  <salaryDialog ref="salaryDialog"/>
  <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
  <sbImage ref="sbImage" />
  <div
    id="message"
    class="el-message el-message--warning"
    style="top:16px;z-index:2087;display: none;align-items: normal;"
  >
    <el-icon style="color: #e6a23c;margin-right: 4px;">
      <warning-filled />
    </el-icon>

    <p class="el-message__content" style="cursor: pointer;">
      温馨提示:有{{notOnList}}家企业未在申报列表：{{notOnCurrentPeriod}}家未结账到本账期（<a  @click="switchTab('1')">点击查看</a>）
      <p style="margin-top: 3px;margin-left: 238px;" v-if="noTax">{{ noTax }}家没有税表（<a @click="switchTab('2')">点击查看</a>）</p>
    </p>
    <el-icon style="margin-left: 10px;color: #999;cursor: pointer;" @click="close">
      <close />
    </el-icon>
  </div>
  <noTaxCategory ref="noTaxCategory" />
  <settleHint />
</template>

<script>
import { companySendTaxList ,companyCarryOverList,exCompanyCarryOver } from "@/api/company"
import { declarationModuleExport } from '@/api/export'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import { oneClickReplicationArr } from "@/api/salary"
import { getUnclosed,getNoTax,getNoTaxExport } from '@/api/carryover'
import selectcity from "@/components/Screening/selectcity";
import checkTbaleDialogs from "./checkTbaleDialog.vue";
import salaryDialog from "../../report/tax/components/salaryDialog.vue";
import jotDown from '@/components/jotDown'
import sbImage from '@/components/sbImage'
import noTaxCategory from './noTaxCategory.vue'
import { ElMessageBox } from 'element-plus'
import {h,ref} from 'vue'
import settleHint from './settleHint.vue'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'


export default {
  name: "batchSendSz",
  components:{
    selecttaxtype,
    declareDetails,
    selectcity,
    checkTbaleDialogs,
    salaryDialog,
    jotDown,
    sbImage,
    noTaxCategory,
    settleHint,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      tempbqf:{},//存放复制比例
      dialogFormVisible:false,//复制往期薪酬
      bfqShow:false,//是否可以编辑复制比例
      activeName:'税款申报',
      listQuery: {
        page: 1,
        limit: 20,
        jzStatus: 0,
        taskName:'gs-sb-',
        name: '',
        period: currentAccountPeriod(),
        businessStatus: [],
        taskStatus: [],
        allStatus: [],
        accountingType: 2,
        taxNames:[],
        from: '个税',
        taxStatus: 0,
        gsMainStatus: 0,
        jotDown:'',
        companyType:''
      },
      total:0,
      list:[],
      gsMainIds: [],
      comIds:[],
      pageStatus: true,
      imgUrl: '',
      options: [
        {
          value: "task-0",
          label: '未申报',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '申报中',
        },
        {
          value: "business-11",
          label: '申报扣款中',
        },
        {
          value: "business-20",
          label: '申报成功',
        },
        {
          value: "business-7",
          label: '申报成功，待缴款',
        },
        {
          value: "business-13",
          label: '申报成功，扣款成功',
        },
        {
          value: "business-10",
          label: '申报成功，无需缴款',
        },
        {
          value: "business-14",
          label: '已申报过',
        },
        {
          value: "business-3",
          label: '超时失败',
        },
        {
          value: "business-5",
          label: '申报异常',
        },
        {
          value: "business-6",
          label: '申报失败',
        },
        {
          value: "business-12",
          label: '扣款异常',
        },{
          value: "business-2",
          label: '异常',
        },
      ],
      categories: [
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
      ],
      selects:[],
      loading:false,
      listLoading:false,
      noteContent:'',
      sbLoading:false,
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false,
      sels:[],
      noTax: 0,
      notOnList:0,
      notOnCurrentPeriod:0,
    }
  },
  created(){
    this.listQuery.limit = localStorage.getItem('batchSendGsSb') ? localStorage.getItem('batchSendGsSb')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(251)
    this.initBus()
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  mounted(){
    this.init()
  },
  methods:{
    // 重置
    cancel(){
      let originLimit =this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        taskName:'gs-sb-',
        name: '',
        period: currentAccountPeriod(),
        businessStatus: [],
        taskStatus: [],
        allStatus: [],
        accountingType: null,
        taxNames:[],
        from: '个税',
        taxStatus: null,
        districtCode:null,
        userId:null,
        type:null,
        jzStatus:null,
        gsMainStatus:null,
        taxType:'gs',
        jotDown:'',
        companyType:''
      }
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
      }
      this.getList()
    },
    init(){
      let box = document.getElementById("message")
      getUnclosed({taxType:'gs'}).then((res) => {
        if (res.data.msg == "success" && res.data.data.notOnList) {
          box.style.display = 'flex';
          this.noTax = res.data.data.noTax;
          this.notOnList = res.data.data.notOnList;
          this.notOnCurrentPeriod = res.data.data.notOnCurrentPeriod;
        }
      });
      setTimeout(()=>{
        box.style.display = 'none';
      },5000)
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(this.activeName == '异常公司' || this.activeName == '本期无需申报'){
          return
        }
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          res.items.forEach(item => {
            if ([1,2,99].includes(item.taskStatus)) {
              status = true;
            }
          })
        })
        if(status == true) {
          companySendTaxList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    open(row, index, bigIndex) {
      let imageAll = []
      let currentIndex = index
      this.list.map((v,i)=>{
        let arr = []
        v.items.map((item,i2)=>{
          let imgList = []
          if(item.image.match(',')){
            let imgUrls = item.image.split(',')
            imgList = imgUrls.filter(v=>{return v})
            imgList.map((e,i3)=>{
              if(e){
                if(item.bussinessStatus == '5' && item.ycExcel){
                  arr.push({
                    img:'https://file.listensoft.net' + e + "?" + Math.random(10),
                    tableName:item.tableName,
                    ycExcel:item.ycExcel
                  })
                }else{
                  arr.push({
                    img:'https://file.listensoft.net' + e + "?" + Math.random(10),
                    tableName:item.tableName,
                  })
                }
                if(i2 < index && i == bigIndex && i3>0){
                  currentIndex ++
                }
              }
            })
          }else{
            if(item.image){
              if(item.bussinessStatus == '5' && item.ycExcel){
                arr.push({
                  img:'https://file.listensoft.net' + item.image + "?" + Math.random(10),
                  tableName:item.tableName,
                  ycExcel:item.ycExcel
                })
              }else{
                arr.push({
                  img:'https://file.listensoft.net' + item.image + "?" + Math.random(10),
                  tableName:item.tableName,
                })
              }
            }else{
              if(i2<index && i == bigIndex){
                currentIndex --
              }
            }
          }
        })
        let url = {
          name:v.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll,bigIndex,currentIndex)
    },
    getList(){
      this.listLoading = true
      if(this.activeName == '异常公司'){
        companyCarryOverList(this.listQuery).then(res=>{
          this.listLoading = false
          this.tableData = res.data.data.list? res.data.data.list : []
          this.total = res.data.data.total
          this.setScroll()
        })
      }else if(this.activeName == '本期无需申报'){
        getNoTax(this.listQuery).then(res=>{
          this.listLoading = false
          this.tableData = res.data.data.notax ? res.data.data.notax : []
          this.total = res.data.data.total
          this.setScroll()
        })
      }else{
        companySendTaxList(this.listQuery).then(res=>{
          this.listLoading = false
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
          this.setScroll()
        })
      }
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.sels = []
      this.selects = []
      this.gsMainIds = []
      this.comIds = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.sels.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.sels.length != 0){
              that.sels.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleClick(tab) {
      this.activeName = tab.props.name
      if(tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
      }else if(tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
      }else{
        this.listQuery.taxType = 'gs'
        this.listQuery.accountingType = 3
      }
      this.getList()
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    getIds(){
      this.gsMainIds = []
      this.selects = []
      this.comIds = []
      this.sels.map(info=>{
        this.comIds.push(info.comId)
        info.items.map(item=>{
          this.gsMainIds.push(item.gsMainId)
        })
      })
      this.selects = {
        period:this.listQuery.period,
        comIds:this.comIds
      }
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.sels = newArr
      }else{
        this.sels = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.sels = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.sels = []
        this.gsMainIds = []
        this.selects = []
        this.comIds = []
        this.allChecked = false
      }
    },
    // 申报详情
    declareDetail(e,v) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,v.taskName)
    },
    //批量申报
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let qsStr = ''
      this.sels.map(item=>{
        item.items.map(v=>{
          if(v.gsMainStatus != 1){
            qsStr = item.name + '暂未取数，不可申报';
            return
          }
        })
      })
      if(qsStr){
        this.$qzfMessage(qsStr, 1)
        return
      }
      let proceed = ''
      this.sels.map(item=>{
        item.items.map(v=>{
          if([1,2,99].includes(v.taskStatus)){
            proceed = item.name + '任务正在进行中，请重新选择！';
            return
          }
        })
      })
      if(proceed){
        this.$qzfMessage(proceed, 1)
        return
      }
      let arr = []
      let ids = []
      this.sels.map(v=>{
        v.items.map(e=>{
          if(e.taskStatus == 3 && [7,10,11,13,20].includes(e.bussinessStatus)){
            arr.push(v.name+"个税已申报成功，不可发起")
          }else{
            ids.push(e.gsMainId)
          }
        })
      })
      if(arr.length != 0){
        ElMessageBox.alert(
          `
          <div style="height:350px;overflow:auto">
            ${
              arr.map(item => {
                return `<div>${item}</div>`
              }).join('')
            }
            </div>
          `
          ,
          {
            dangerouslyUseHTMLString:true,
            confirmButtonText: '确认',
          }
        )
      }
      if(ids.length != 0){
        this.sbLoading = true
        let param = {
          taskName: 'tax_sb',
          gsMainIds: ids
        }
        sendTask(param).then(res => {
          this.sbLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      }
    },
    //申报
    handleUpdate(e) {
      this.$confirm('确定要申报吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true
        let param = {
          taskName: 'tax_sb',
          gsMainIds: [e.gsMainId]
        }
        sendTask(param).then(res => {
          this.listLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
    //复制往期薪酬
    batchCopySalary() {
      if(!this.selects.comIds || this.selects.comIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.dialogFormVisible =true;
      this.tempbqf.bfq =100
      this.bfqShow = true;
    },
    // 编辑复制比例
    editBfq(){
      this.bfqShow = false;
    },
    editSaveBfq(){
      this.bfqShow = true;
    },
    // 保存复制比例
    saveBfq(){
      this.selects.bfb  = Number(this.tempbqf.bfq);
      oneClickReplicationArr(this.selects).then(e => {
        if(e.data.msg == 'success') {
          this.$qzfMessage("复制成功")
          this.dialogFormVisible =false;
          this.getList()
        }
      });
    },
    //导出
    daochu(){
      if(this.activeName == '异常公司'){
        let param = {
          comIds:this.comIds,
          info:this.listQuery
        }
        exCompanyCarryOver(param).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }else if(this.activeName == '本期无需申报'){
        let param = {
          comIds:this.comIds,
          info:this.listQuery
        }
        getNoTaxExport(param).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }else{
        let param = {
          query:this.listQuery,
          comIds: this.comIds
        }
        declarationModuleExport(param).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.url)
          }
        })
      }
     
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    eval1(row){
      return eval(row)?.toFixed(2)
    },
    //查看报表
    checkTableDialog(row,item){
      if(item.tableName == 'gs_gs'){
        this.$refs.salaryDialog.init(row.comId,this.listQuery.period,row.name)
      }else{
        let param = {
          comId:row.comId,
          period:this.listQuery.period,
          tableName:item.tableName,
          comName:row.name,
        }
        this.$refs.checkTbaleDialogs.openDialog(param)
      }
    },
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.comId,this.listQuery.period)
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.comId == id){
          v.jotDown = text
        }
      })
    },
    switchTab(flag){
      if(flag == '1'){
        this.activeName = "异常公司"
        this.listQuery.accountingType = 3
      }else{
        this.listQuery.accountingType = 3
        this.listQuery.taxType = 'gs'
        this.activeName = "本期无需申报"
      }
      this.getList()
    },
    checkCate(row){
      this.$refs.noTaxCategory.init(row)
    },
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 5px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
// .xgmStyle,.ybStyle {
//   margin-left: 10px;
// }
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
.export_btn{
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
:deep(textarea){
  min-height: 145px !important;
}
.el-message__content a{
  text-decoration: underline;
  color: #409eff;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}
.loudou .el-icon-arrow-down:before{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}

</style>

<style lang="scss">
  .classPrompt{
    .el-message-box__container{
      color:red
    }
}
</style>