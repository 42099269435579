<template>
  <div class="app-container">
   <!-- todo -->
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 6px;width:685px" class="styleForm" :inline="true" label-width="100px" size="small">
            <el-form-item label="选择人员 ：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:170px;margin-left: 0;"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型 ：">          
              <selecttaxtype v-model:type="listQuery.type" style="width:170px;margin-left: 0;"></selecttaxtype>
            </el-form-item> 
            <el-form-item label="税局 ："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:170px;margin-left: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="报税手机号 ：">          
              <el-input v-model.trim="listQuery.tel" style="width:170px;"></el-input>
            </el-form-item>
            <el-form-item label="登录状态 ：">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:170px;margin-left: 0;"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:170px;margin-left: 0;">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否零申报 ：">          
              <el-radio-group v-model="listQuery.accountingType" style="width:270px;margin-left: 0;">
              <el-radio :label="3">全部</el-radio>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group> 
            </el-form-item>
            <el-form-item label="申报状态：">
              <el-checkbox-group v-model="listQuery.allStatus">
                <!-- <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button> -->
                <div>  
                  <el-checkbox-button v-for="(item, index) in options.slice(0, 5)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in options.slice(5, 10)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
                <div>  
                  <el-checkbox-button v-for="(item, index) in options.slice(10, 15)" :key="item.value" :label="item.value" @change="changeValue">  
                    {{ item.label }}  
                  </el-checkbox-button>  
                </div> 
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="任务状态：">
              <selectCjTaskStatus v-model:taskStatus="listQuery.taskStatus"></selectCjTaskStatus>
            </el-form-item>
            <el-form-item label="有无税款 ：">          
              <el-radio-group v-model="listQuery.taxStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">有税款</el-radio>
                <el-radio :label="2">无税款</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" type="primary" @click="declaration()" :disabled="!$buttonStatus('pljc_sjjc_jc')" :loading="jcLoading">
          <el-icon><Checked /></el-icon><span  > 批量采集</span>
        </el-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" v-loading="listLoading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column align="left" prop="name" label="公司名称" min-width="240" class="gsmc">
        <template #default="scope">
          <TagNameCopy :row="scope.row"  idKey="comId"></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="accountingType" label="是否零申报" width="90">
        <template #default="scope">
          <span v-if="scope.row.accountingType == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column> 
      <el-table-column  prop="district" label="税局" width="65">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district)  }}</span>  
        </template>
      </el-table-column>
      <el-table-column align="left" prop="districtName" label="申报结果" min-width="180">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$setUpStatusGsIconSb(scope.row.taskStatusSb,scope.row.bussinessStatusSb)"></i>
                <span :class="$setUpStatusGsWordSb(scope.row.taskStatusSb,scope.row.bussinessStatusSb)">
                  {{$setUpStatusGsSb(scope.row.taskStatusSb,scope.row.bussinessStatusSb)}}
                </span>
              </p>
            </div>
          </template>
        </el-table-column>
      <el-table-column prop="districtName" label="任务状态" min-width="180">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <span v-if="(scope.row.image) && [3,4].includes(scope.row.taskStatus)">
                  <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="scope.row.taskStatus == 4" @click="openImg(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-pdf" style="color:#67c23a;font-size:16px;" v-if="scope.row.taskStatus == 3" @click="open(scope.row, scope.$index)"></i>
                </span>
                <i :class="$setUpStatusIconJc(scope.row.taskStatus, 'task')" v-else></i>
                <el-tooltip :disabled="scope.row.errLog == ''" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.errLog?scope.row.errLog:''"></div>
                  </template>
                  <p>{{$setUpStatusWsJc(scope.row.taskStatus, "task")}}<i class="iconfont icon-wenhao" v-if="scope.row.taskStatus == 4 && scope.row.errLog" style="color:#f56c6c;margin:0 0 0 4px;"></i></p>
                </el-tooltip>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="manager" label="操作" min-width="180">
        <template #default="scope">
          <div class="div_p">
            <el-tooltip :disabled="scope.row.taskStatusSb == 3 && scope.row.bussinessStatusSb != 7 && scope.row.bussinessStatusSb != 10"  effect="dark" placement="top-start">
              <template #content>
                {{ scope.row?.bussinessStatusSb == 10? '无需获取完税证明' : '请先完成扣款后再采集' }}
              </template>
              <el-button @click="handleUpdate(scope.row)" size="small" type="text" :disabled="!$buttonStatus('pljc_sjjc_jc') || scope.row.taskStatusSb != 3 || scope.row.bussinessStatusSb != 13">采集</el-button>
            </el-tooltip>
            <el-button :disabled="scope.row.taskStatus == 0" @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button>
          </div>
        </template>
      </el-table-column>
      
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="taxCheckProve"/>
    </div>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImage ref="sbImage"/>
</template>

<script>
import { companyWsInitTaxList } from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import selectCjTaskStatus from '../components/select/selectCjTaskStatus.vue'
import { adaptiveSize2A5PDF } from "@/api/printSet";
import sbImage from '@/components/sbImage'
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "taxCheck",
  components:{
    selecttaxtype,
    declareDetails,
    selectcity,
    selectAccreditStatus,
    TagNameCopy,
    TableSortCell,
    selectCjTaskStatus,
    sbImage
  },
  data(){
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      listQuery: {
        page: 1,
        limit: 20,
        taskName:"gs-cj-wszm",
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        accountingType: 3,
        taxNames:[],
        status: 2,
        tel:"",
        from:"个税",
        companyType:'',
        taxStatus: 0,
      },
      total:0,
      list:[],
      gsMainIds: undefined,
      pageStatus: true,
      listLoading:false,
      jcLoading:false,
      dialogFormVisible:false,
      checkList:[],
      sels:[],
      options: [
      {
          value: "task-0",
          label: '未申报',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '申报中',
        },
        {
          value: "business-11",
          label: '申报扣款中',
        },
        {
          value: "business-20",
          label: '申报成功',
        },
        {
          value: "business-7",
          label: '申报成功，待缴款',
        },
        {
          value: "business-13",
          label: '申报成功，扣款成功',
        },
        {
          value: "business-10",
          label: '申报成功，无需缴款',
        },
        {
          value: "business-14",
          label: '已申报过',
        },
        {
          value: "business-3",
          label: '超时失败',
        },
        {
          value: "business-5",
          label: '申报异常',
        },
        {
          value: "business-6",
          label: '申报失败',
        },
        {
          value: "business-12",
          label: '扣款异常',
        },{
          value: "business-2",
          label: '异常',
        },
      ],
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.listQuery.limit = localStorage.getItem('taxCheckProve') ? localStorage.getItem('taxCheckProve')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(220)
    this.initBus()
  },
  mounted(){
  },
  methods:{
    initBus(){
      if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
      this.$bus.on("refreshTask", () => {
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if ([1,2,99].includes(res.taskStatus)) {
              status = true;
            }
        })
        if(status == true) {
          companyWsInitTaxList(this.listQuery).then(res=>{
            this.list = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
          })
        }
      });
    },
    getList(){
      this.listLoading = true
      companyWsInitTaxList(this.listQuery).then(res=>{
        this.listLoading = false
        this.list = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
      })
    },
    open(row) {
      let param = {
        markType: '个税完税证明',
        imgPath: row.image,
      };
      adaptiveSize2A5PDF(param).then((res) => {
        if (res.data.msg == "success") {
          window.open("https://file.listensoft.net" + res.data.data.path);
        }
      });
    },
    openImg(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.image.match(',')){
          let imgUrls = res.image.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                  img:'https://file.listensoft.net' + item,
                  tableName:""
                })
            }
          })
        }else{
          if(res.image){
            arr.push({
              img:'https://file.listensoft.net' + res.image,
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    handleSelectionChange(row) {
      this.sels = row
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,e.taskName)
    },
    //批量申报
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let wxkkStr = this.sels.find((v) => v.taskStatusSb == 3 && v.bussinessStatusSb == 10);
      if (wxkkStr) {
        this.$qzfMessage(`${wxkkStr.name}无需获取完税证明，请重新选择`,1);
        return;
      }
      let djkStr = this.sels.find((v) => v.taskStatusSb != 3 || v.bussinessStatusSb == 7 || v.bussinessStatusSb != 13);
      if (djkStr) {
        this.$qzfMessage(`${djkStr.name}请先完成扣款后再采集！`,1);
        return;
      }
      this.$confirm('确定要批量采集吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.jcLoading = true
        let param = {
          taskName: 'gs-cj-wszm',
          comIds: this.gsMainIds,
          period: this.listQuery.period
        }
        sendTask(param).then(res => {
          this.jcLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    //申报
    handleUpdate(e) {
      this.$confirm('确定要采集吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'gs-cj-wszm',
          comIds: [e.comId],
          period: this.listQuery.period
        }
        this.listLoading = true
        sendTask(param).then(res => {
          this.listLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery = {
        page: 1,
        limit: originLimit,
        taskName:"gs-cj-wszm",
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        accountingType: 3,
        taxNames:[],
        status: 2,
        tel:"",
        from:"个税",
        companyType:'',
        taxStatus: 0,
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.filter-item1 {
  margin-right: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }

  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
</style>